import { Grid, Typography } from "@material-ui/core";
import React, { useContext, useMemo } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import FormSection from "../../../../../components/molecules/form-section";
import Notice, { Varaint } from "../../../../../components/molecules/notice";
import FormDropdown, {
  LABEL_POSITION,
} from "../../../../../components/molecules/form-dropdown";
import FormTextInput from "../../../../../components/molecules/form-text-input";
import envelop from "../../../../../../images/address-envelop.png";
import AppContext from "../../../../../context";
import { ENABLE_BOOLEAN_OPTIONS } from "../../../../../static-data";
import { useSelector } from "react-redux";
import Constants from "../../../../../config/constant";
import { APP_LANG } from "../../../../../utils/locale-handler";
import FormMeta from "../../schema/meta.json";
import { fieldVisibility, trimFieldValue } from "../../../../../utils";

const OfflineResponses = () => {
  const { t } = useTranslation();
  const { appLanguage: lang } = useContext(AppContext);
  const { values, errors, submitCount, handleChange, setFieldValue } =
    useFormikContext();
  const { countries } = useSelector((state) => state.genericData);

  const countryName = useMemo(() => {
    const country = countries.find(
      (element) => element.code === Constants.LANKA_COUNTRY_CODE
    );
    if (country === undefined) {
      return "";
    }
    return country[lang === APP_LANG.EN ? "name" : "name_si"];
  }, [countries, lang]);

  const showFieldErrors = submitCount > 0;

  const showAddressFields = useMemo(() => {
    const { showOfflineId, subscribeMagazine } = values;
    return showOfflineId || subscribeMagazine;
  }, [values]);

  return (
    <FormSection
      id="offline-responses"
      heading={t("createflow.offlineResponses")}
      subHeading={t("createflow.offlineResponsesSubheading")}
    >
      <Grid
        item
        container
        xs={12}
        md={6}
        direction="row"
        style={{ rowGap: 16, paddingTop: "1rem" }}
      >
        <FormDropdown
          fieldName={FormMeta.showOfflineId.fieldName}
          value={values.showOfflineId.toString()}
          onChange={(e) =>
            setFieldValue(
              FormMeta.showOfflineId.fieldName,
              JSON.parse(e.target?.value ?? "")
            )
          }
          label={t(FormMeta.showOfflineId.label)}
          labelPosition={LABEL_POSITION.top}
          lang={lang}
          options={ENABLE_BOOLEAN_OPTIONS}
          errorText={showFieldErrors && errors.showOfflineId}
          visibility={fieldVisibility(FormMeta.showOfflineId)}
        />
        <FormDropdown
          fieldName={FormMeta.subscribeMagazine.fieldName}
          value={values.subscribeMagazine.toString()}
          onChange={(e) =>
            setFieldValue(
              FormMeta.subscribeMagazine.fieldName,
              JSON.parse(e.target?.value ?? "")
            )
          }
          label={t(FormMeta.subscribeMagazine.label)}
          labelPosition={LABEL_POSITION.top}
          lang={lang}
          options={ENABLE_BOOLEAN_OPTIONS}
          keyExtractor={FormMeta.subscribeMagazine.keyExtractor}
          labelExtractor={FormMeta.subscribeMagazine.labelExtractor}
          errorText={showFieldErrors && errors.subscribeMagazine}
          visibility={fieldVisibility(FormMeta.subscribeMagazine)}
        />
      </Grid>
      {showAddressFields && (
        <Grid
          item
          container
          direction="column"
          style={{ marginTop: 16, rowGap: 16 }}
        >
          <Grid item container direction="row" style={{ rowGap: 8 }}>
            <Typography>{t("createflow.mailingAddress")}</Typography>
            <Notice
              varaint={Varaint.primary}
              label={t("createflow.mailingAddressNotice")}
              lang={lang}
            />
          </Grid>

          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ columnGap: 8, rowGap: 16 }}
          >
            <Grid style={{ rowGap: 16 }} item container xs={12} md={6}>
              <FormTextInput
                fieldName={FormMeta.nameOfRecipient.fieldName}
                value={values.nameOfRecipient}
                onChange={handleChange}
                label={t(FormMeta.nameOfRecipient.label)}
                errorText={showFieldErrors && errors.nameOfRecipient}
                visibility={fieldVisibility(FormMeta.nameOfRecipient)}
                onBlur={() => {
                  trimFieldValue({
                    fieldName: FormMeta.nameOfRecipient.fieldName,
                    value: values[FormMeta.nameOfRecipient.fieldName],
                    setFieldValue,
                  });
                }}
                max={FormMeta.nameOfRecipient.max}
                maxCharactorCount={FormMeta.nameOfRecipient.max}
              />
              <FormTextInput
                fieldName={FormMeta.addressLineOne.fieldName}
                label={t(FormMeta.addressLineOne.label)}
                value={values.addressLineOne}
                onChange={handleChange}
                errorText={showFieldErrors && errors.addressLineOne}
                visibility={fieldVisibility(FormMeta.addressLineOne)}
                onBlur={() => {
                  trimFieldValue({
                    fieldName: FormMeta.addressLineOne.fieldName,
                    value: values[FormMeta.addressLineOne.fieldName],
                    setFieldValue,
                  });
                }}
                max={FormMeta.addressLineOne.max}
                maxCharactorCount={FormMeta.addressLineOne.max}
              />
              <FormTextInput
                fieldName={FormMeta.addressLineTwo.fieldName}
                label={t(FormMeta.addressLineTwo.label)}
                value={values.addressLineTwo}
                onChange={handleChange}
                errorText={showFieldErrors && errors.addressLineTwo}
                visibility={fieldVisibility(FormMeta.addressLineTwo)}
                onBlur={() => {
                  trimFieldValue({
                    fieldName: FormMeta.addressLineTwo.fieldName,
                    value: values[FormMeta.addressLineTwo.fieldName],
                    setFieldValue,
                  });
                }}
                max={FormMeta.addressLineTwo.max}
                maxCharactorCount={FormMeta.addressLineTwo.max}
              />
              <FormTextInput
                fieldName={FormMeta.city.fieldName}
                label={t(FormMeta.city.label)}
                value={values.city}
                onChange={handleChange}
                errorText={showFieldErrors && errors.city}
                visibility={fieldVisibility(FormMeta.city)}
                onBlur={() => {
                  trimFieldValue({
                    fieldName: FormMeta.city.fieldName,
                    value: values[FormMeta.city.fieldName],
                    setFieldValue,
                  });
                }}
                max={FormMeta.city.max}
                maxCharactorCount={FormMeta.city.max}
              />
              <FormTextInput
                fieldName={FormMeta.country.fieldName}
                value={countryName}
                onChange={handleChange}
                label={t(FormMeta.country.label)}
                errorText={showFieldErrors && errors.country}
                disabled={true}
                visibility={fieldVisibility(FormMeta.country)}
              />
            </Grid>

            <Grid item xs={12} md={5}>
              <Grid style={{ position: "relative", maxWidth: "100%" }}>
                <img src={envelop} width="100%" alt="post-envelope" />
                <Grid
                  style={{
                    position: "absolute",
                    top: "40%",
                    right: "10%",
                    overflowWrap: "break-word",
                    maxWidth: "80%",
                  }}
                >
                  <Typography variant="body2">
                    {values.nameOfRecipient}
                  </Typography>
                  <Typography variant="body2">
                    {values.addressLineOne}
                  </Typography>
                  <Typography variant="body2">
                    {values.addressLineTwo}
                  </Typography>
                  <Typography variant="body2">{values.city}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </FormSection>
  );
};

export default OfflineResponses;
