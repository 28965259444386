import { Grid, Link, Typography } from "@material-ui/core";
import { ChildCare } from "@material-ui/icons";
import React from "react";
import "./style.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useContext } from "react";
import AppContext from "../../../context";
import { useTranslation } from "react-i18next";

const ReviewSection = ({
  heading,
  navigate,
  scrollElement,
  children,
  className = "",
}) => {
  const history = useHistory();
  const { appLanguage } = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="column"
      className={`review-section-wrapper ${className}`}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ columnGap: 8 }}
      >
        <Typography
          className={`review-section-heading review-section-heading-${appLanguage}`}
        >
          {heading}
        </Typography>
        <Link
          className={`navigation-link navigation-link-${appLanguage}`}
          // onClick={() => history.push(`/${navigate}`)}
          // onClick={() => history.push({
          //   pathname: `/${navigate}`,
          //   state: {
          //     scrollElement: scrollElement
          //   }
          // })}
          onClick={() => {
            if (scrollElement) {
              const url = `/${navigate}#${scrollElement}`;
              history.push(url);
            } else {
              history.push(`/${navigate}`);
            }
          }}
        >
          ({t("common.edit")})
        </Link>
      </Grid>
      {children}
    </Grid>
  );
};

export default ReviewSection;
