import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Button,
} from "@material-ui/core";
import WarningRounded from "@material-ui/icons/WarningRounded";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import moment from "moment";
import { isEqual, isEmpty } from "lodash";

import { SectionHeading } from "../custom-typography";
import Notice from "../../../../../components/molecules/notice";
import FormMeta from "../../../../../meta/edit-self-profile.json";
import {
  Base64ToBlob,
  fieldVisibility,
  FileToBase64,
  isEmptyOrNullOrUndefined,
  resizeImageToUpload,
  scrollOnError,
  trimFieldValue,
} from "../../../../../utils";
import FormTextInput from "../../../../../components/molecules/form-text-input";
import FormControl from "../../../../../components/molecules/form-control";
import FormImageSelector from "../../../../../components/molecules/form-image-selector";
import {
  createEditRequest,
  deleteHoroscopeImage,
  deleteProfileImages,
  downloadAdProfileImages,
  downloadHoroscopeImages,
  downloadPendingAdImages,
  getAdvertisement,
  handleFieldOnBlur,
  isFileOrBlob,
  REVIEW_FORM_FIELDS,
} from "../../../../../features/edit-ad";
import ImageRestrictionNotice from "../../../../create-flow/private-create-account/templates/image-restriction-notice";
import AlertDialogBox from "../../../../../components/molecules/dialog-box";
import ImageCrop from "../../../../../../components/image-crop/ImageCrop";
import Config from "../../../../../config";
import { getAuthUserProfile } from "../../../../../features/application";
import { setEditAdPageImagesLoading } from "../../../../../store/actions/edit-ad-page";
import { profileStatusForUpdatingNameFields } from "../Information-without-review/basic-info-form";

const { POST_IMAGE_SLOTS, POST_HOROSCOPE_IMAGE_SLOTS } = Config;
const EDIT_AD_IMAGE_VARIANTS = Object.freeze({
  PROFILE_IMAGE: "PROFILE_IMAGE",
  HOROSCOPE_IMAGE: "HOROSCOPE_IMAGE",
});

const InformationWithReview = ({ contentLoading }) => {
  const [showImagesRestrictionModal, setShowImagesRestrictionModal] =
    useState(false);
  const [profilePictures, setProfilePictures] = useState(
    Array.from(Array(POST_IMAGE_SLOTS).fill(undefined))
  );
  const [horoscopePictures, setHoroscopePictures] = useState(
    Array.from(Array(POST_HOROSCOPE_IMAGE_SLOTS).fill(undefined))
  );
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [submitCount, setSubmitCount] = useState(0);
  const dispatch = useDispatch();
  const { profileImages, horoscopeImages, advertisement, imagesLoading } =
    useSelector((state) => state.editAdPage);
  const { t, i18n } = useTranslation();
  const {
    values,
    errors,
    getFieldMeta,
    handleChange,
    setFieldError,
    setFieldValue,
    setErrors,
    resetForm,
  } = useFormikContext();

  const canUpdateNameFields = useMemo(() => {
    const adStatus = advertisement.status ?? "";
    const idVerified = advertisement.idVerified ?? false;

    return (
      !profileStatusForUpdatingNameFields.includes(adStatus) && !idVerified
    );
  }, [advertisement]);

  const hasPendingEditRequsts = useMemo(() => {
    return !isEmptyOrNullOrUndefined(advertisement.pendingEditRequests);
  }, [advertisement]);

  const hasChangedFormFields = useMemo(() => {
    let output = false;
    for (const current of REVIEW_FORM_FIELDS) {
      const fieldMeta = getFieldMeta(current.fieldName);
      if (!isEmptyOrNullOrUndefined(fieldMeta)) {
        const { initialValue, value } = fieldMeta;
        if (!isEqual(value, initialValue)) {
          output = true;
        }
      }
    }

    if (isFileOrBlob(profilePictures)) {
      output = true;
    }
    if (isFileOrBlob(horoscopePictures)) {
      output = true;
    }

    return output;
  }, [getFieldMeta, profilePictures, horoscopePictures]);

  const onSubmitReview = async () => {
    setSubmitCount(submitCount + 1);
    isEmpty(errors) &&
      dispatch(
        createEditRequest({
          getFieldMeta,
          t,
          values,
          setFieldValue,
          images: profilePictures,
          horoscopeImages: horoscopePictures,
        })
      );
  };

  const onClickCancel = () => {
    setErrors({});
    resetForm();

    if (Array.isArray(profilePictures)) {
      for (let [index, image] of profilePictures.entries()) {
        if (image instanceof Blob) {
          let tempArray = [...profilePictures];
          tempArray[index] = undefined;
          setProfilePictures(tempArray);
        }
      }
    }

    if (Array.isArray(horoscopePictures)) {
      for (let [index, image] of horoscopePictures.entries()) {
        if (image instanceof Blob) {
          let tempArray = [...horoscopePictures];
          tempArray[index] = undefined;
          setHoroscopePictures(tempArray);
        }
      }
    }
  };

  const onSelectImage = async ({ file, index, variant }) => {
    try {
      const base64 = await FileToBase64(file);
      setSelectedImage({
        slotId: index,
        source: base64,
        variant: variant,
      });
    } catch (e) {
      console.error(">>>> [Error!] ", e);
    }
  };

  const handleChangeFile = async (e) => {
    const blob = await Base64ToBlob(e);
    resizeImageToUpload({
      blob,
      _onResize: (outputBlob) => {
        if (selectedImage !== undefined) {
          let tempArray = [];
          if (
            selectedImage?.variant === EDIT_AD_IMAGE_VARIANTS.HOROSCOPE_IMAGE
          ) {
            tempArray = [...horoscopePictures];
            tempArray[selectedImage.slotId] = outputBlob;
            setHoroscopePictures(tempArray);
            setSelectedImage(undefined);
          }
          if (selectedImage?.variant === EDIT_AD_IMAGE_VARIANTS.PROFILE_IMAGE) {
            tempArray = [...profilePictures];
            tempArray[selectedImage.slotId] = outputBlob;
            setProfilePictures(tempArray);
            setSelectedImage(undefined);
          }
        }
      },
      get onResize() {
        return this._onResize;
      },
      set onResize(value) {
        this._onResize = value;
      },
    });
  };

  const onDeleteImage = async ({ idx, variant }) => {
    const formImages =
      variant === EDIT_AD_IMAGE_VARIANTS.PROFILE_IMAGE
        ? values.images
        : variant === EDIT_AD_IMAGE_VARIANTS.HOROSCOPE_IMAGE
        ? values.horoscopeImages
        : [];

    if (formImages[idx]) {
      if (variant === EDIT_AD_IMAGE_VARIANTS.PROFILE_IMAGE) {
        const response = await deleteProfileImages({
          postId: advertisement.id,
          imageId: formImages[idx],
        });
        if (response.success) {
          let newArr = formImages.filter((_, i) => i !== idx);
          setFieldValue(FormMeta.imagesReview.fieldName, newArr);
          dispatch(getAdvertisement());
          dispatch(getAuthUserProfile());
        }
      }

      if (variant === EDIT_AD_IMAGE_VARIANTS.HOROSCOPE_IMAGE) {
        const response = await deleteHoroscopeImage({
          postId: advertisement.id,
          imageId: formImages[idx],
        });
        if (response.success) {
          let newArr = formImages.filter((_, i) => i !== idx);
          setFieldValue(FormMeta.horoscopeImages.fieldName, newArr);
          dispatch(getAdvertisement());
          dispatch(getAuthUserProfile());
        }
      }
    } else {
      let tempFormImages =
        variant === EDIT_AD_IMAGE_VARIANTS.PROFILE_IMAGE
          ? [...profilePictures]
          : variant === EDIT_AD_IMAGE_VARIANTS.HOROSCOPE_IMAGE
          ? [...horoscopePictures]
          : [];

      tempFormImages[idx] = undefined;

      if (variant === EDIT_AD_IMAGE_VARIANTS.PROFILE_IMAGE) {
        setProfilePictures(tempFormImages);
      }

      if (variant === EDIT_AD_IMAGE_VARIANTS.HOROSCOPE_IMAGE) {
        setHoroscopePictures(tempFormImages);
      }
    }
  };

  const downloadEditAdImages = useCallback(async () => {
    dispatch(setEditAdPageImagesLoading(true));
    setProfilePictures(Array.from(Array(POST_IMAGE_SLOTS).fill(undefined)));
    setHoroscopePictures(
      Array.from(Array(POST_HOROSCOPE_IMAGE_SLOTS).fill(undefined))
    );
    if (!isEmpty(advertisement.pendingEditRequests)) {
      await dispatch(
        downloadPendingAdImages({
          postId: advertisement.id,
          advertisement,
        })
      );
    } else {
      await dispatch(
        downloadAdProfileImages({
          postId: advertisement.id,
          sources: values.images,
        })
      );
      await dispatch(
        downloadHoroscopeImages({
          postId: advertisement.id,
          sources: values.horoscopeImages ?? [],
        })
      );
    }
    dispatch(setEditAdPageImagesLoading(false));
  }, [advertisement, values.images, values.horoscopeImages, dispatch]);

  useEffect(() => {
    downloadEditAdImages();
  }, [downloadEditAdImages]);

  useEffect(() => {
    setHoroscopePictures(horoscopeImages);
  }, [horoscopeImages]);

  useEffect(() => {
    setProfilePictures(profileImages);
  }, [profileImages]);

  if (contentLoading) {
    return (
      <Paper variant="outlined" className="form-section-paper">
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="full-height-container"
        >
          <CircularProgress className="branded-circullar-progress" />
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper variant="outlined" className="form-section-paper">
      <SectionHeading labelKey="common.reviewAndUpdate" />
      {!hasPendingEditRequsts && (
        <Notice
          label={t("common.reviewAndUpdateSectionNotice")}
          lang={i18n.language}
        />
      )}
      <Grid container direction="column" className="row-gap-16 margin-top-8">
        <FormTextInput
          mode="standard"
          fieldName={FormMeta.firstNameReview.fieldName}
          value={values[FormMeta.firstNameReview.fieldName]}
          onChange={handleChange}
          label={t(FormMeta.firstNameReview.label)}
          lang={i18n.language}
          errorText={
            submitCount > 0 && errors[FormMeta.firstNameReview.fieldName]
          }
          visibility={
            fieldVisibility(FormMeta.firstNameReview) && canUpdateNameFields
          }
          onBlur={() => {
            trimFieldValue({
              fieldName: FormMeta.firstNameReview.fieldName,
              value: values[FormMeta.firstNameReview.fieldName],
              setFieldValue,
            });
          }}
          disabled={hasPendingEditRequsts}
          maxCharactorCount={FormMeta.firstNameReview.max}
          max={FormMeta.firstNameReview.max}
        />
        <FormTextInput
          mode="standard"
          fieldName={FormMeta.lastNameReview.fieldName}
          value={values[FormMeta.lastNameReview.fieldName]}
          onChange={handleChange}
          label={t(FormMeta.lastNameReview.label)}
          lang={i18n.language}
          errorText={
            submitCount > 0 && errors[FormMeta.lastNameReview.fieldName]
          }
          visibility={
            fieldVisibility(FormMeta.lastNameReview) && canUpdateNameFields
          }
          onBlur={() => {
            trimFieldValue({
              fieldName: FormMeta.lastNameReview.fieldName,
              value: values[FormMeta.lastNameReview.fieldName],
              setFieldValue,
            });
          }}
          disabled={hasPendingEditRequsts}
          maxCharactorCount={FormMeta.lastNameReview.max}
          max={FormMeta.lastNameReview.max}
        />
        <FormTextInput
          mode="standard"
          fieldName={FormMeta.subCommunityReview.fieldName}
          value={values[FormMeta.subCommunityReview.fieldName]}
          onChange={handleChange}
          label={t(FormMeta.subCommunityReview.label)}
          lang={i18n.language}
          errorText={errors[FormMeta.subCommunityReview.fieldName]}
          visibility={fieldVisibility(FormMeta.subCommunityReview)}
          onBlur={() => {
            trimFieldValue({
              fieldName: FormMeta.subCommunityReview.fieldName,
              value: values[FormMeta.subCommunityReview.fieldName],
              setFieldValue,
            });
          }}
          disabled={hasPendingEditRequsts}
          maxCharactorCount={FormMeta.subCommunityReview.max}
          max={FormMeta.subCommunityReview.max}
        />
        <FormTextInput
          mode="standard"
          fieldName={FormMeta.casteReview.fieldName}
          value={values[FormMeta.casteReview.fieldName]}
          onChange={handleChange}
          label={t(FormMeta.casteReview.label)}
          lang={i18n.language}
          errorText={submitCount > 0 && errors[FormMeta.casteReview.fieldName]}
          visibility={fieldVisibility(FormMeta.casteReview)}
          onBlur={() => {
            trimFieldValue({
              fieldName: FormMeta.casteReview.fieldName,
              value: values[FormMeta.casteReview.fieldName],
              setFieldValue,
            });
          }}
          disabled={hasPendingEditRequsts}
          maxCharactorCount={FormMeta.casteReview.max}
          max={FormMeta.casteReview.max}
        />
        <FormTextInput
          fieldName={FormMeta.adDescriptionReview.fieldName}
          value={values[FormMeta.adDescriptionReview.fieldName]}
          onChange={handleChange}
          onBlur={(e) => {
            trimFieldValue({
              fieldName: FormMeta.adDescriptionReview.fieldName,
              value: values[FormMeta.adDescriptionReview.fieldName],
              setFieldValue,
            });
            dispatch(handleFieldOnBlur(e, setFieldError, t));
          }}
          label={t(FormMeta.adDescriptionReview.label)}
          placeholder={t(FormMeta.adDescriptionReview.placeholder)}
          lang={i18n.language}
          multiline
          errorText={errors[FormMeta.adDescriptionReview.fieldName]}
          disabled={hasPendingEditRequsts}
          maxCharactorCount={FormMeta.adDescriptionReview.max}
          max={FormMeta.adDescriptionReview.max}
        />
        <FormTextInput
          fieldName={FormMeta.fAdditionalInfoReview.fieldName}
          value={values[FormMeta.fAdditionalInfoReview.fieldName]}
          onChange={handleChange}
          onBlur={(e) => {
            trimFieldValue({
              fieldName: FormMeta.fAdditionalInfoReview.fieldName,
              value: values[FormMeta.fAdditionalInfoReview.fieldName],
              setFieldValue,
            });
            dispatch(handleFieldOnBlur(e, setFieldError, t));
          }}
          label={t(FormMeta.fAdditionalInfoReview.label, {
            parent: t("common.father"),
          })}
          placeholder={t(FormMeta.fAdditionalInfoReview.placeholder)}
          lang={i18n.language}
          multiline
          errorText={errors[FormMeta.fAdditionalInfoReview.fieldName]}
          disabled={hasPendingEditRequsts}
          maxCharactorCount={FormMeta.fAdditionalInfoReview.max}
          max={FormMeta.fAdditionalInfoReview.max}
        />
        <FormTextInput
          fieldName={FormMeta.mAdditionalInfoReview.fieldName}
          value={values[FormMeta.mAdditionalInfoReview.fieldName]}
          onChange={handleChange}
          onBlur={(e) => {
            trimFieldValue({
              fieldName: FormMeta.mAdditionalInfoReview.fieldName,
              value: values[FormMeta.mAdditionalInfoReview.fieldName],
              setFieldValue,
            });
            dispatch(handleFieldOnBlur(e, setFieldError, t));
          }}
          label={t(FormMeta.mAdditionalInfoReview.label, {
            parent: t("common.mother"),
          })}
          placeholder={t(FormMeta.mAdditionalInfoReview.placeholder)}
          lang={i18n.language}
          multiline
          errorText={errors[FormMeta.mAdditionalInfoReview.fieldName]}
          disabled={hasPendingEditRequsts}
          maxCharactorCount={FormMeta.mAdditionalInfoReview.max}
          max={FormMeta.mAdditionalInfoReview.max}
        />
      </Grid>
      <FormControl
        label={t("common.yourPhotoes")}
        classes={{ rootContainer: "photo-form-control" }}
      >
        <Grid
          container
          row
          style={{ justifyContent: "flex-start", columnGap: 8 }}
          className="row-gap-8"
        >
          {profilePictures.map((src, idx) => (
            <Grid item style={{ width: "30%" }} key={`profile-img-slot-${idx}`}>
              <FormImageSelector
                image={src}
                disabled={hasPendingEditRequsts}
                onSelect={(file) =>
                  onSelectImage({
                    file,
                    index: idx,
                    variant: EDIT_AD_IMAGE_VARIANTS.PROFILE_IMAGE,
                  })
                }
                onDelete={() =>
                  onDeleteImage({
                    idx,
                    variant: EDIT_AD_IMAGE_VARIANTS.PROFILE_IMAGE,
                  })
                }
                isLoading={imagesLoading}
              />
            </Grid>
          ))}
        </Grid>
      </FormControl>
      <Grid
        container
        direction="row"
        className="clickable-instruction-container"
      >
        <WarningRounded className="clickable-instruction" />
        <Typography
          className={`clickable-instruction clickable-instruction-${i18n.language}`}
          onClick={() => setShowImagesRestrictionModal(true)}
        >
          {t("createflow.pictureRestrictionNotice")}
        </Typography>
      </Grid>
      <FormControl
        label={t("common.horoscopeDetails")}
        classes={{ rootContainer: "photo-form-control" }}
      >
        <Grid
          container
          row
          style={{ justifyContent: "flex-start", columnGap: 8 }}
        >
          {horoscopePictures.map((src, idx) => (
            <Grid item style={{ width: "30%" }} key={`profile-img-slot-${idx}`}>
              <FormImageSelector
                image={src}
                disabled={hasPendingEditRequsts}
                onSelect={(file) =>
                  onSelectImage({
                    file,
                    index: idx,
                    variant: EDIT_AD_IMAGE_VARIANTS.HOROSCOPE_IMAGE,
                  })
                }
                onDelete={() =>
                  onDeleteImage({
                    idx,
                    variant: EDIT_AD_IMAGE_VARIANTS.HOROSCOPE_IMAGE,
                  })
                }
                isLoading={imagesLoading}
              />
            </Grid>
          ))}
        </Grid>
      </FormControl>
      <FormTextInput
        fieldName={FormMeta.horoscopeDetailReview.fieldName}
        value={values[FormMeta.horoscopeDetailReview.fieldName]}
        onChange={handleChange}
        onBlur={(e) => {
          trimFieldValue({
            fieldName: FormMeta.horoscopeDetailReview.fieldName,
            value: values[FormMeta.horoscopeDetailReview.fieldName],
            setFieldValue,
          });
          dispatch(handleFieldOnBlur(e, setFieldError, t));
        }}
        label={t(FormMeta.horoscopeDetailReview.label)}
        placeholder={t(FormMeta.horoscopeDetailReview.placeholder)}
        lang={i18n.language}
        multiline
        errorText={errors[FormMeta.horoscopeDetailReview.fieldName]}
        disabled={hasPendingEditRequsts}
        maxCharactorCount={FormMeta.horoscopeDetailReview.max}
        max={FormMeta.horoscopeDetailReview.max}
      />

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        className="submit-action-container"
      >
        {hasPendingEditRequsts ? (
          <Typography variant="body2" className="review-in-progress-txt">
            {t("message.reviewInProgress")}
          </Typography>
        ) : (
          <>
            <Button
              variant="text"
              className="cancel-btn"
              disabled={!hasChangedFormFields}
              onClick={() => onClickCancel()}
            >
              {t("createflow.cancel")}
            </Button>
            <Button
              variant="contained"
              className="review-btn"
              disabled={!hasChangedFormFields}
              onClick={() => {
                onSubmitReview();
                setTimeout(() => {
                  scrollOnError();
                }, [500]);
              }}
            >
              {t("createflow.submitForReview")}
            </Button>
          </>
        )}
      </Grid>
      <AlertDialogBox
        showModal={showImagesRestrictionModal}
        onClose={() => setShowImagesRestrictionModal(false)}
        title={t("createflow.pictureModalNotice")}
      >
        <ImageRestrictionNotice />
      </AlertDialogBox>
      <ImageCrop
        uploadImageModal={selectedImage !== undefined}
        selectedImage={selectedImage?.source}
        handleClose={() => setSelectedImage(undefined)}
        handleChangeFile={handleChangeFile}
        photoLoading={false}
        handleSave={() => {}}
        lng={true}
      />
    </Paper>
  );
};

export default InformationWithReview;
