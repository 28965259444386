// Package Imports
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useContext } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

// Repository Implementations
import FormSection from "../../../../../components/molecules/form-section";
import FormTextInput from "../../../../../components/molecules/form-text-input";
import Notice, { Varaint } from "../../../../../components/molecules/notice";
import AppContext from "../../../../../context";
import FormMeta from "../../schema/meta.json";
import { fieldVisibility, trimFieldValue } from "../../../../../utils";

// Main Component
const ContactInformation = () => {
  const { t } = useTranslation();
  const { appLanguage: lang } = useContext(AppContext);
  const { values, errors, submitCount, setFieldValue, handleChange } =
    useFormikContext();

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const showFieldErrors = submitCount > 0;
  const labelStyles = {
    ...(isMobileScreen ? {} : { width: "20%" }),
  };

  return (
    <FormSection heading={t("common.contactDetails")}>
      <Grid
        container
        direction="column"
        style={{ rowGap: 32, paddingTop: "1rem" }}
      >
        <Grid item container xs={12} md={12} style={{ rowGap: 8 }}>
          <Grid item container xs={12} md={6}>
            <FormTextInput
              fieldName={FormMeta.phoneNumber.fieldName}
              value={values.phoneNumber}
              onChange={handleChange}
              label={t(FormMeta.phoneNumber.label)}
              disabled={true}
              lang={lang}
              labelStyles={labelStyles}
              visibility={fieldVisibility(FormMeta.phoneNumber)}
            />
          </Grid>
          <Grid item container xs={12} style={{ justifyContent: "right" }}>
            <Notice
              varaint={Varaint}
              label={t("createflow.phoneNumberNotice")}
              lang={lang}
              isRightAligned={true}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} md={12} style={{ rowGap: 8 }}>
          <Grid item container xs={12} md={6}>
            <FormTextInput
              fieldName={FormMeta.email.fieldName}
              value={values.email}
              onChange={handleChange}
              label={t(FormMeta.email.label)}
              lang={lang}
              labelStyles={labelStyles}
              errorText={showFieldErrors && errors.email}
              visibility={fieldVisibility(FormMeta.email)}
              onBlur={() => {
                trimFieldValue({
                  fieldName: FormMeta.email.fieldName,
                  value: values[FormMeta.email.fieldName],
                  setFieldValue,
                });
              }}
              hideCharactorLength={true}
            />
          </Grid>
          <Grid item container xs={12} style={{ justifyContent: "right" }}>
            <Notice
              varaint={Varaint}
              label={t("createflow.emailNotice")}
              lang={lang}
              isRightAligned={true}
              noticeList={[
                t("createflow.emailNoticeFirstBullet"),
                t("createflow.emailNoticeSecondBullet"),
                t("createflow.emailNoticeThirdBullet"),
                t("createflow.emailNoticeFourthBullet"),
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </FormSection>
  );
};

export default ContactInformation;
