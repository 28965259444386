import React, { Component } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import axios from "axios";
// import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import Config from "../v2/config";
import CompleteItem from "../components/complete-your-ad/CompleteItem";
import PageHead from "../components/common/PageHead";
import Unauthorize from "../pages/Unauthorize";
import groom from "../images/groom.png";
import bride from "../images/bride.png";
import mail from "../images/mail.png";
import picture from "../images/picture.png";
import family from "../images/family.png";
import id from "../images/id.png";
import feedback from "../images/feedback.png";
import topAdBadge from "../images/premium-quality.png";
import "../styles/complete-your-ad.css";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class Settings extends Component {
  state = {
    isAccessDenied: false,
    userId: "",
    name: "",
    postStatus: "",
    isOffline: false,
    idVerified: false,
    isFeatured: false,
    isPhotosUploaded: false,
    isSiblingsData: false,
    isSetInterestPreferences: false,
    gender: "MALE",
  };

  componentDidMount = () => {
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.setState({
      isAccessDenied: localStorage.getItem("auth_token") ? false : true,
      userId: localStorage.getItem("userId")
        ? localStorage.getItem("userId")
        : "",
      name: localStorage.getItem("post_details")
        ? JSON.parse(localStorage.getItem("post_details")).personalInfo.fname +
          " " +
          JSON.parse(localStorage.getItem("post_details")).personalInfo.lname
        : "",
      postStatus: localStorage.getItem("post_details")
        ? JSON.parse(localStorage.getItem("post_details")).status
        : "INCOMPLETE",
    });
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/account/posts`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        this.setState({
          isOffline: res.data[0].offlineId ? true : false,
          idVerified: res.data[0].idVerified,
          isFeatured: res.data[0].isFeatured,
          isPhotosUploaded: res.data[0].images.length > 0 ? true : false,
          isSiblingsData: res.data[0].siblingInfo.length > 0 ? true : false,
          isSetInterestPreferences: res.data[0].interestPreferences
            ? true
            : false,
          gender: res.data[0].personalInfo.gender,
        });
      });
  };

  navigateFunction = (path, divName) => {
    this.props.history.push({ pathname: path, state: divName });
  };

  openModal = () => {
    this.props.history.push("/payments/top-ad");
  };

  render() {
    return this.state.isAccessDenied ? (
      <Unauthorize
        title={`Attract More Responses | ${Config.DOMAIN_DISPLAY_NAME}`}
      />
    ) : (
      <React.Fragment>
        <PageHead
          title={`Attract More Responses | ${Config.DOMAIN_DISPLAY_NAME}`}
        />
        {/* <Navbar handleLng={this.handleLng} lng={this.state.lng} /> */}
        <div style={{ background: "#dfdfdf", paddingTop: 5 }}>
          <Typography align="center" className="header">
            <p className="title-p">
              {this.props.lng ? (
                "Attract More Responses"
              ) : (
                <span>දැන්වීම ආකර්ෂණීය කරන්න</span>
              )}
            </p>
            <div className="row">
              <div className="col settings-div" style={{ width: "100%" }}>
                <p className="ad-id-p">
                  {this.props.lng ? "Ad ID" : "දැන්වීම් කේතය"}
                </p>
                <p className="user-id">{this.state.userId}</p>
                <p className="applicant-photo-main-div">
                  {this.state.gender === "MALE" ? (
                    <img src={groom} className="applicant-photo" alt="groom" />
                  ) : (
                    <img src={bride} className="applicant-photo" alt="bride" />
                  )}
                </p>
                <p className="name-main-div">
                  <Typography variant="h6" className="name">
                    <span
                      style={{ textTransform: "capitalize", color: "#333" }}
                    >
                      {this.state.name}
                    </span>
                  </Typography>
                </p>
                <p className="status-div">
                  {this.state.postStatus === "LIVE" ? (
                    <p className="status-lable live">
                      {this.props.lng ? (
                        "Live"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-10">
                          සජීවි
                        </span>
                      )}{" "}
                    </p>
                  ) : this.state.postStatus === "PAYMENT_PENDING" ? (
                    <p className="status-lable pending-payment">
                      {this.props.lng ? (
                        "Payment Pending"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-10">
                          ගෙවීම් අපේක්ෂිතය
                        </span>
                      )}
                    </p>
                  ) : this.state.postStatus === "IN_REVIEW" ? (
                    <p className="status-lable in-review">
                      {this.props.lng ? (
                        "In Review"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-10">
                          සමාලෝචනය කරමින්
                        </span>
                      )}
                    </p>
                  ) : this.state.postStatus === "DISABLED" ? (
                    <p className="status-lable unpublished">
                      {this.props.lng ? (
                        "Unpublished"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-10">
                          ඉවත් කර ඇත
                        </span>
                      )}
                    </p>
                  ) : this.state.postStatus === "EXPIRED" ? (
                    <p className="status-lable expired">
                      {this.props.lng ? (
                        "Expired"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-10">
                          කල් ඉකුත් වී ඇත
                        </span>
                      )}
                    </p>
                  ) : (
                    <p className="status-lable incomplete">
                      {this.props.lng ? (
                        "Incomplete"
                      ) : (
                        <span className="sinhala-w-600 sinhala-size-10">
                          අසම්පූර්ණයි
                        </span>
                      )}
                    </p>
                  )}
                </p>
              </div>
            </div>
          </Typography>
          <Grid
            container
            xs={12}
            sm={10}
            md={8}
            className="cus-container complete-ad-container"
          >
            {!this.state.idVerified && (
              <CompleteItem
                lng={this.props.lng}
                img={id}
                path="/settings"
                title={
                  this.props.lng ? "ID Verification" : "අනන්‍යතාවය තහවුරු කිරීම"
                }
                description={
                  this.props.lng
                    ? "Increase the credibility of your profile by becoming a verified user."
                    : "අනන්‍යතාවය තහවුරු කිරීමෙන් ඔබේ පැතිකඩෙහි විශ්වසනීයත්වය වැඩි කරන්න."
                }
                description2={
                  this.props.lng
                    ? "All you need to do is upload a scanned image of a government issued photo ID. You can do this from the Settings section."
                    : "ඔබ කළ යුත්තේ රජය විසින් නිකුත් කරන ලද ඡායාරූප හැඳුනුම්පතක ස්කෑන් කළ රූපයක් ඇතුලත් කිරීම පමණි. ඔබට මෙය සැකසීම් අංශයෙන් කළ හැක."
                }
                url={this.navigateFunction}
                isDisable={this.state.idVerified}
                scrollTo="nic-div"
                btnText={this.props.lng ? "Become Verified" : "තහවුරු කර ගන්න"}
              />
            )}
            {!this.state.isFeatured && Config.TOP_AD_FEATURE_ENABLED && (
              <CompleteItem
                lng={this.props.lng}
                img={topAdBadge}
                path="/interest-preferences"
                title="Top Ad"
                description={
                  this.props.lng
                    ? "Feature your ad in search results."
                    : "සෙවුම් ප්‍රතිඵලවල ඔබේ දැන්වීම විශේෂාංගගත කරන්න."
                }
                description2={
                  this.props.lng
                    ? "You can increase the visibility of your ad by making it appear at the top of user’s search results for up to 7 days. Go to the Settings section to make your ad featured. "
                    : "ඔබට ඔබගේ දැන්වීම දින 7ක් දක්වා පරිශීලකයාගේ සෙවුම් ප්‍රතිඵලවල ඉහළින්ම දිස්වීමට සැලැස්වීමෙන් අමතර ආකර්ශනයක් ලබා ගත හැක. ඔබගේ දැන්වීම විශේෂාංගගත කිරීමට සැකසීම් කොටස වෙත යන්න."
                }
                url={this.navigateFunction}
                isDisable={this.state.isFeatured}
                scrollTo="make-top-ad-div"
                btnText={this.props.lng ? "Feature My Ad" : "විශේෂාංගගත කරන්න"}
                openModalAction={this.openModal}
              />
            )}

            {!this.state.isPhotosUploaded && (
              <CompleteItem
                lng={this.props.lng}
                img={picture}
                path="/edit-my-ad"
                title={this.props.lng ? "Photos" : "ඡායාරූප"}
                description={
                  this.props.lng
                    ? "Add photos to make your profile more attractive."
                    : "ඔබගේ දැන්වීම වඩාත් ආකර්ෂණීය කිරීමට ඡායාරූප එක් කරන්න."
                }
                description2={
                  this.props.lng
                    ? "You will also not be able to see photos of other users until you upload photos to your profile. You can add photos from the Edit My Ad section."
                    : "ඔබ ඔබේ දැන්වීමට ඡායාරූප එක් කරන තෙක් ඔබට වෙනත් පරිශීලකයින්ගේ ඡායාරූප බැලීමට නොහැකි වනු ඇත. ඔබට දැන්වීම සංස්කරණය කරන්න පිටුවෙන්  ඡායාරූප එක් කළ හැක."
                }
                url={this.navigateFunction}
                isDisable={this.state.isPhotosUploaded}
                scrollTo="add-photos-div"
                btnText={this.props.lng ? "Add Photos" : "ඡායාරූප එක් කරන්න"}
              />
            )}

            {Config.OFFLINE_RESPONSES_VISIBILITY && !this.state.isOffline && (
              <CompleteItem
                lng={this.props.lng}
                img={mail}
                title={
                  this.props.lng
                    ? "Offline Responses"
                    : "ලිපි මගින් පිළිතුරු ලබා ගැනීම"
                }
                description={
                  this.props.lng
                    ? "Setup your preferences for accepting responses via post and receiving the monthly magazine."
                    : "ලිපි මගින් ප්‍රතිචාර ලබාගැනීමට සහ මාසික සඟරාව ලබා ගැනීමට ඔබගේ සැකසුම් වෙනස් කරන්න."
                }
                description2={
                  this.props.lng
                    ? "Set your preferences in Settings -> Offline Responses."
                    : "එසේ කිරීමට 'සැකසුම් -> ලිපි මගින් පිළිතුරු ලබාගැනීම' වෙත පිවිසෙන්න."
                }
                url={this.navigateFunction}
                path="/settings"
                isDisable={this.state.isOffline}
                scrollTo="offline-responses-div"
                btnText={this.props.lng ? "Go to Settings" : "සැකසුම වෙත යන්න"}
              />
            )}

            {!this.state.isSetInterestPreferences && (
              <CompleteItem
                lng={this.props.lng}
                img={feedback}
                path="/interest-preferences"
                title={
                  this.props.lng
                    ? "Interest Preferences"
                    : "කැමැත්තන් පිළිගැනීමේ නිර්ණායක"
                }
                description={
                  this.props.lng
                    ? "Control who sends you interest requests."
                    : "ඔබට කැමැත්ත පෙන්වීමට හැක්කේ කාටද යන්න පාලනය කරන්න."
                }
                description2={
                  this.props.lng
                    ? "You can set preferences for who can send you an interest based on age, religion, residency, civil status and more. You can setup your preferences from the Interest Preferences section."
                    : "වයස, ආගම, පදිංචිය, සිවිල් තත්ත්වය සහ තවත් දේ මත පදනම්ව ඔබට කැමැත්ත පෙන්විය හැක්කේ කාටද යන්න සඳහා ඔබට මනාප සැකසිය හැක. ඔබට 'කැමැත්ත පෙන්වීමේ නිර්ණායක' අංශයෙන් ඔබේ මනාපයන් සැකසිය හැක."
                }
                url={this.navigateFunction}
                isDisable={this.state.isSetInterestPreferences}
                scrollTo="offline-responses-div"
                btnText={
                  this.props.lng
                    ? "Interest Preferences"
                    : "කැමැත්තන් පිළිගැනීමේ නිර්ණායක"
                }
              />
            )}

            {!this.state.isSiblingsData && (
              <CompleteItem
                lng={this.props.lng}
                img={family}
                path="/edit-my-ad"
                title={this.props.lng ? "Siblings" : "සහෝදර සහෝදරියන්"}
                description={this.props.lng ? "Family is important!" : ""}
                description2={
                  this.props.lng
                    ? "Add information about your brothers and sisters in the Edit My Ad section."
                    : "ඔබගේ දැන්වීම සංස්කරණය කරන්න පිටුවෙන් ඔබේ සහෝදර සහෝදරියන් පිළිබඳ තොරතුරු එක් කර හැක"
                }
                url={this.navigateFunction}
                isDisable={this.state.isSiblingsData}
                scrollTo="siblings-info-div"
                btnText={
                  this.props.lng ? "Add Siblings" : "සහෝදර සහෝදරියන් එකතු කරන්න"
                }
              />
            )}

            {/* completed tasks */}
            {this.state.idVerified && (
              <CompleteItem
                lng={this.props.lng}
                img={id}
                path="/settings"
                title={
                  this.props.lng ? "ID Verification" : "අනන්‍යතාවය තහවුරු කිරීම"
                }
                description={
                  this.props.lng
                    ? "Increase the credibility of your profile by becoming a verified user."
                    : "අනන්‍යතාවය තහවුරු කිරීමෙන් ඔබේ පැතිකඩෙහි විශ්වසනීයත්වය වැඩි කරන්න."
                }
                description2={
                  this.props.lng
                    ? "All you need to do is upload a scanned image of a government issued photo ID. You can do this from the Settings section."
                    : "ඔබ කළ යුත්තේ රජය විසින් නිකුත් කරන ලද ඡායාරූප හැඳුනුම්පතක ස්කෑන් කළ රූපයක් ඇතුලත් කිරීම පමණි. ඔබට මෙය සැකසීම් අංශයෙන් කළ හැක."
                }
                url={this.navigateFunction}
                isDisable={this.state.idVerified}
                scrollTo="nic-div"
                btnText={this.props.lng ? "Become Verified" : "තහවුරු කර ගන්න"}
              />
            )}

            {this.state.isFeatured && (
              <CompleteItem
                lng={this.props.lng}
                img={topAdBadge}
                path="/interest-preferences"
                title="Top Ad"
                description={
                  this.props.lng
                    ? "Feature your ad in search results."
                    : "සෙවුම් ප්‍රතිඵලවල ඔබේ දැන්වීම විශේෂාංගගත කරන්න."
                }
                description2={
                  this.props.lng
                    ? "You can increase the visibility of your ad by making it appear at the top of user’s search results for up to 7 days. Go to the Settings section to make your ad featured. "
                    : "ඔබට ඔබගේ දැන්වීම දින 7ක් දක්වා පරිශීලකයාගේ සෙවුම් ප්‍රතිඵලවල ඉහළින්ම දිස්වීමට සැලැස්වීමෙන් අමතර ආකර්ශනයක් ලබා ගත හැක. ඔබගේ දැන්වීම විශේෂාංගගත කිරීමට සැකසීම් කොටස වෙත යන්න."
                }
                url={this.navigateFunction}
                isDisable={this.state.isFeatured}
                scrollTo="make-top-ad-div"
                btnText={this.props.lng ? "Feature My Ad" : "විශේෂාංගගත කරන්න"}
                openModalAction={this.openModal}
              />
            )}

            {this.state.isPhotosUploaded && (
              <CompleteItem
                lng={this.props.lng}
                img={picture}
                path="/edit-my-ad"
                title={this.props.lng ? "Photos" : "ඡායාරූප"}
                description={
                  this.props.lng
                    ? "Add photos to make your profile more attractive."
                    : "ඔබගේ දැන්වීම වඩාත් ආකර්ෂණීය කිරීමට ඡායාරූප එක් කරන්න."
                }
                description2={
                  this.props.lng
                    ? "You will also not be able to see photos of other users until you upload photos to your profile. You can add photos from the Edit My Ad section."
                    : "ඔබ ඔබේ දැන්වීමට ඡායාරූප එක් කරන තෙක් ඔබට වෙනත් පරිශීලකයින්ගේ ඡායාරූප බැලීමට නොහැකි වනු ඇත. ඔබට දැන්වීම සංස්කරණය කරන්න පිටුවෙන්  ඡායාරූප එක් කළ හැක."
                }
                url={this.navigateFunction}
                isDisable={this.state.isPhotosUploaded}
                scrollTo="add-photos-div"
                btnText={this.props.lng ? "Add Photos" : "ඡායාරූප එක් කරන්න"}
              />
            )}

            {Config.OFFLINE_RESPONSES_VISIBILITY && this.state.isOffline && (
              <CompleteItem
                lng={this.props.lng}
                img={mail}
                title={
                  this.props.lng
                    ? "Offline Responses"
                    : "ලිපි මගින් පිළිතුරු ලබා ගැනීම"
                }
                description={
                  this.props.lng
                    ? "Setup your preferences for accepting responses via post and receiving the monthly magazine."
                    : "ලිපි මගින් ප්‍රතිචාර ලබාගැනීමට සහ මාසික සඟරාව ලබා ගැනීමට ඔබගේ සැකසුම් වෙනස් කරන්න."
                }
                description2={
                  this.props.lng
                    ? "Set your preferences in Settings -> Offline Responses."
                    : "එසේ කිරීමට 'සැකසුම් -> ලිපි මගින් පිළිතුරු ලබාගැනීම' වෙත පිවිසෙන්න."
                }
                url={this.navigateFunction}
                path="/settings"
                isDisable={this.state.isOffline}
                scrollTo="offline-responses-div"
                btnText={this.props.lng ? "Go to Settings" : "සැකසුම වෙත යන්න"}
              />
            )}

            {this.state.isSetInterestPreferences && (
              <CompleteItem
                lng={this.props.lng}
                img={feedback}
                path="/interest-preferences"
                title={
                  this.props.lng
                    ? "Interest Preferences"
                    : "කැමැත්තන් පිළිගැනීමේ නිර්ණායක"
                }
                description={
                  this.props.lng
                    ? "Control who sends you interest requests."
                    : "ඔබට කැමැත්ත පෙන්වීමට හැක්කේ කාටද යන්න පාලනය කරන්න."
                }
                description2={
                  this.props.lng
                    ? "You can set preferences for who can send you an interest based on age, religion, residency, civil status and more. You can setup your preferences from the Interest Preferences section."
                    : "වයස, ආගම, පදිංචිය, සිවිල් තත්ත්වය සහ තවත් දේ මත පදනම්ව ඔබට කැමැත්ත පෙන්විය හැක්කේ කාටද යන්න සඳහා ඔබට මනාප සැකසිය හැක. ඔබට 'කැමැත්ත පෙන්වීමේ නිර්ණායක' අංශයෙන් ඔබේ මනාපයන් සැකසිය හැක."
                }
                url={this.navigateFunction}
                isDisable={this.state.isSetInterestPreferences}
                scrollTo="offline-responses-div"
                btnText={
                  this.props.lng
                    ? "Interest Preferences"
                    : "කැමැත්තන් පිළිගැනීමේ නිර්ණායක"
                }
              />
            )}

            {this.state.isSiblingsData && (
              <CompleteItem
                lng={this.props.lng}
                img={family}
                path="/edit-my-ad"
                title={this.props.lng ? "Siblings" : "සහෝදර සහෝදරියන්"}
                description={this.props.lng ? "Family is important!" : ""}
                description2={
                  this.props.lng
                    ? "Add information about your brothers and sisters in the Edit My Ad section."
                    : "ඔබගේ දැන්වීම සංස්කරණය කරන්න පිටුවෙන් ඔබේ සහෝදර සහෝදරියන් පිළිබඳ තොරතුරු එක් කර හැක"
                }
                url={this.navigateFunction}
                isDisable={this.state.isSiblingsData}
                scrollTo="siblings-info-div"
                btnText={
                  this.props.lng ? "Add Siblings" : "සහෝදර සහෝදරියන් එකතු කරන්න"
                }
              />
            )}
            <Grid container direction="row" justifyContent="flex-end">
              <Button
                variant="contained"
                className="all-ads-btn all-ads-btn-attract-responses"
                onClick={() => this.props.history.push("/")}
              >
                {this.props.lng ? (
                  "Go To ALL Ads"
                ) : (
                  <span className="sinhala-w-600 sinhala-size-10">
                    සියලුම දැන්වීම් වෙත යන්න
                  </span>
                )}
              </Button>
            </Grid>
          </Grid>
        </div>
        <Footer lng={this.props.lng} />
      </React.Fragment>
    );
  }
}

export default withLanguageMigrator(Settings);
