import * as Yup from "yup";
import Config from "../../../../config";
import FormMeta from "./meta.json";

const CommonSchema = (t) => {
  return Yup.object().shape({
    [FormMeta.images.fieldName]: Yup.array(),
    [FormMeta.horoscopeImages.fieldName]: Yup.array(),
    [FormMeta.birthdate.fieldName]: Yup.string(),
    [FormMeta.birthTime.fieldName]: Yup.string(),
    [FormMeta.birthCity.fieldName]: Yup.string(),
    [FormMeta.originCountryCode.fieldName]: Yup.string().required(
      t("errors.formRequiredField", {
        fieldName: t("common.birthCountry"),
      })
    ),
    [FormMeta.horoscopeMatching.fieldName]: Yup.boolean().required(
      t("errors.formRequiredField", {
        fieldName: t("createflow.horoscopeMatchingRequired"),
      })
    ),
    [FormMeta.horoscopeDetail.fieldName]: Yup.string(),
    [FormMeta.phoneNumber.fieldName]: Yup.string(),
    [FormMeta.email.fieldName]: Yup.string().matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      t("errors.invalidFieldValue", { fieldName: t("common.email") })
    ),
  });
};

const PlatformSpecificSchema = (t) => {
  const { CURRENT_PLATFORM, PLATFORMS } = Config;
  switch (CURRENT_PLATFORM) {
    case PLATFORMS.IN: {
      return Yup.object().shape({
        [FormMeta.starId.fieldName]: Yup.string().optional(),
        [FormMeta.acceptOtherCaste.fieldName]: Yup.string().optional(),
        [FormMeta.haveDosh.fieldName]: Yup.string().optional(),
        [FormMeta.acceptOtherCaste.fieldName]: Yup.string().optional(),
      });
    }
    default: {
      return Yup.object().shape({
        [FormMeta.showOfflineId.fieldName]: Yup.boolean().required(
          t("errors.formRequiredField", {
            fieldName: t("createflow.acceptWrittenInterestsViaPost"),
          })
        ),
        [FormMeta.subscribeMagazine.fieldName]: Yup.boolean().required(
          t("errors.formRequiredField", {
            fieldName: t("createflow.SendMeMonthlyMagazine"),
          })
        ),
        [FormMeta.nameOfRecipient.fieldName]: Yup.string()
          // .matches(
          //   /[`0-9!@#$%^&*()_+\-=[\]{};':"\\|<>/?~]/,
          //   t("createflow.reciepientNamePatternError")
          // )
          .when(
            ["showOfflineId", "subscribeMagazine"],
            ([showOfflineId, subscribeMagazine], schema) => {
              if (showOfflineId || subscribeMagazine) {
                return schema.required(
                  t("errors.formRequiredField", {
                    fieldName: t("createflow.nameOfRecipient"),
                  })
                );
              }
            }
          ),
        [FormMeta.addressLineOne.fieldName]: Yup.string().when(
          ["showOfflineId", "subscribeMagazine"],
          ([showOfflineId, subscribeMagazine], schema) => {
            if (showOfflineId || subscribeMagazine) {
              return schema.required(
                t("errors.formRequiredField", {
                  fieldName: t("createflow.addressLineOne"),
                })
              );
            }
          }
        ),
        [FormMeta.addressLineTwo.fieldName]: Yup.string(),
        city: Yup.string().when(
          ["showOfflineId", "subscribeMagazine"],
          ([showOfflineId, subscribeMagazine], schema) => {
            if (showOfflineId || subscribeMagazine) {
              return schema.required(
                t("errors.formRequiredField", {
                  fieldName: t("common.city"),
                })
              );
            }
          }
        ),
        country: Yup.string(),
      });
    }
  }
};

const FormSchema = (t) => {
  const formScheam = CommonSchema(t);
  const platformSchema = PlatformSpecificSchema(t);
  return formScheam.concat(platformSchema);
};

export default FormSchema;
