import * as Yup from "yup";
import FormMeta from "../../../../../../../meta/sibling-form.json";
import {
  buildFormErrorMessage,
  FormErrorMessage,
  isOtherProfession,
  isStringContainsEmoji,
  isStringContainsNumbers,
  isStringContainsSpecialCharacters,
} from "../../../../../../../utils";

const CommonSchema = (t) => {
  return Yup.object().shape({
    [FormMeta.relationship.fieldName]: Yup.string().required(
      buildFormErrorMessage(
        t,
        FormErrorMessage.REQUIRED_FIELD,
        FormMeta.relationship.fieldName
      )
    ),
    [FormMeta.isMarried.fieldName]: Yup.string().required(
      buildFormErrorMessage(
        t,
        FormErrorMessage.REQUIRED_FIELD,
        FormMeta.isMarried.fieldName
      )
    ),
    [FormMeta.profession.fieldName]: Yup.string().required(
      buildFormErrorMessage(
        t,
        FormErrorMessage.REQUIRED_FIELD,
        FormMeta.profession.fieldName
      )
    ),
    [FormMeta.otherProfession.fieldName]: Yup.string()
      .when(FormMeta.profession.fieldName, ([professionId], scheam) => {
        if (isOtherProfession(professionId)) {
          return scheam.required(
            buildFormErrorMessage(
              t,
              FormErrorMessage.REQUIRED_FIELD,
              FormMeta.otherProfession.label
            )
          );
        }
      })
      .max(
        FormMeta.otherProfession.max,
        t("errors.formFieldTooLong", { fieldName: t("common.typeProfession") })
      )
      .test({
        test: (value) => {
          return !(
            isStringContainsEmoji(value) ||
            isStringContainsNumbers(value) ||
            isStringContainsSpecialCharacters(value)
          );
        },
        message: t("errors.invalidFieldValue", {
          fieldName: t("common.typeProfession"),
        }),
      }),
  });
};
const FormSchema = (t) => {
  const schema = CommonSchema(t);
  return schema;
};

export default FormSchema;
