import {
  SET_HOME_PAGE_COMMUNITIES,
  SET_HOME_PAGE_COUNTRY_REGIONS,
  SET_HOME_PAGE_CURRENT_PAGE,
  SET_HOME_PAGE_FEATURED_POSTS,
  SET_HOME_PAGE_LOADING,
  SET_HOME_PAGE_PAGE_SIZE,
  SET_HOME_PAGE_POSTS,
  SET_HOME_PAGE_PREFFERRED_ACTIVE,
  SET_HOME_PAGE_PREFFERRED_CRITERIA,
  SET_HOME_PAGE_SHADOWED_CRTIERIA,
  SET_HOME_PAGE_TOTAL_COUNT,
  SET_HOME_PAGE_TOTAL_PAGES,
  SET_HOME_PAGE_MOBILE_FILTER_DRAWER,
  SET_HOME_PAGE_SHOW_SAVE_PREFFERRED_CRITERIA_BTN,
  SET_HOME_PAGE_SAVING_PREFFERRED_CRITERIA,
  SET_HOME_PAGE_MOBILE_SAVE_PREFFERRED_CRITERIA,
  SET_HOME_PAGE_FILTER_COUNT,
  SET_HOME_PAGE_INITIAL_RESULT_LOADED,
  SET_HOME_PAGE_SHOULD_FORCE_RELOAD,
} from "../../actions/home-page";

const initialState = {
  loading: false,
  posts: [],
  featuredPosts: [],
  totalCount: 0,
  totalPages: 0,
  currentPage: 0,
  pageSize: 0,
  countryRegions: [],
  communities: [],
  shadowedCriteria: {},
  isActivePrefferredSearch: false,
  prefferredSearchCriteria: {},
  showMobileFilterDrawer: false,
  showSavePrefferredCriteriaBtn: true,
  savingPrefferredCriteria: false,
  mobileSavePrefferredCriteria: false,
  filterCount: 0,
  initialResultLoaded: false,
  shouldForceReload: false,
};

const homePageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_HOME_PAGE_POSTS:
      return { ...state, posts: payload };
    case SET_HOME_PAGE_FEATURED_POSTS:
      return { ...state, featuredPosts: payload };
    case SET_HOME_PAGE_TOTAL_COUNT:
      return { ...state, totalCount: payload };
    case SET_HOME_PAGE_TOTAL_PAGES:
      return { ...state, totalPages: payload };
    case SET_HOME_PAGE_CURRENT_PAGE:
      return { ...state, currentPage: payload };
    case SET_HOME_PAGE_PAGE_SIZE:
      return { ...state, pageSize: payload };
    case SET_HOME_PAGE_COUNTRY_REGIONS:
      return { ...state, countryRegions: payload };
    case SET_HOME_PAGE_COMMUNITIES:
      return { ...state, communities: payload };
    case SET_HOME_PAGE_SHADOWED_CRTIERIA:
      return { ...state, shadowedCriteria: payload };
    case SET_HOME_PAGE_LOADING:
      return { ...state, loading: payload };
    case SET_HOME_PAGE_PREFFERRED_CRITERIA:
      return { ...state, prefferredSearchCriteria: payload };
    case SET_HOME_PAGE_PREFFERRED_ACTIVE:
      return { ...state, isActivePrefferredSearch: payload };
    case SET_HOME_PAGE_MOBILE_FILTER_DRAWER:
      return { ...state, showMobileFilterDrawer: payload };
    case SET_HOME_PAGE_SHOW_SAVE_PREFFERRED_CRITERIA_BTN:
      return { ...state, showSavePrefferredCriteriaBtn: payload };
    case SET_HOME_PAGE_SAVING_PREFFERRED_CRITERIA:
      return { ...state, savingPrefferredCriteria: payload };
    case SET_HOME_PAGE_MOBILE_SAVE_PREFFERRED_CRITERIA:
      return { ...state, mobileSavePrefferredCriteria: payload };
    case SET_HOME_PAGE_FILTER_COUNT:
      return { ...state, filterCount: payload };
    case SET_HOME_PAGE_INITIAL_RESULT_LOADED:
      return { ...state, initialResultLoaded: payload };
    case SET_HOME_PAGE_SHOULD_FORCE_RELOAD:
      return { ...state, shouldForceReload: payload };
    default:
      return state;
  }
};

export default homePageReducer;
