import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import DataColumn from "../../../../../../components/molecules/data-column";
import ReviewSection from "../../../../../../components/molecules/review-section";
import AppContext from "../../../../../../context";
import { OfflineResponseDisplayModel } from "../../../../../../models/profile";
import {
  DataDisplaySection,
  generateDisplayValues,
} from "../../../../../../features/createflow";
import { fieldVisibility } from "../../../../../../utils";
import privateFormMeta from "../../../../private-create-account/schema/meta.json";
import reviewAndPayMeta from "../../../schema/formMeta.json";
import { Chip, Grid, Typography } from "@material-ui/core";

const formMeta = { ...privateFormMeta, ...reviewAndPayMeta };

const ReviewOfflineResponses = () => {
  const { t, i18n } = useTranslation();
  const { appLanguage } = useContext(AppContext);
  const { initialized } = useSelector((state) => state.genericData);
  const { authTempProfile } = useSelector((state) => state.application);
  const [displayValues, setDisplayValues] = useState(
    OfflineResponseDisplayModel
  );
  const showAddressField = useMemo(() => {
    return (
      displayValues.showOfflineId === "Enable" ||
      displayValues.subscribeMagazine === "Enable"
    );
  }, [displayValues]);

  useEffect(() => {
    if (initialized && !isEmpty(authTempProfile)) {
      generateDisplayValues({
        section: DataDisplaySection.offlineResponses,
        lang: appLanguage,
      }).then((value) => setDisplayValues(value));
    }
  }, [appLanguage, initialized, authTempProfile]);

  return (
    <ReviewSection
      heading={t("createflow.offlineResponses")}
      navigate={"complete-ad-private"}
      scrollElement="offline-responses"
    >
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        className="data-column-container"
      >
        <DataColumn
          label={t(formMeta.showOfflineId.label)}
          value={displayValues.showOfflineId}
          visibility={fieldVisibility(formMeta.showOfflineId)}
        />
        <DataColumn
          label={t(formMeta.subscribeMagazine.label)}
          value={displayValues.subscribeMagazine}
          visibility={fieldVisibility(formMeta.subscribeMagazine)}
        />
      </Grid>
      {showAddressField && (
        <>
          <Grid container direction="row" className="column-gap-8">
            <Typography
              className={`review-section-title typo-h4-${i18n.language} capitalized-text`}
            >
              {t("createflow.mailingAddress")}
            </Typography>
            <Chip
              size="small"
              label="Private"
              color="primary"
              className="data-section-chip"
            />
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            className="data-column-container"
          >
            <DataColumn
              label={t(formMeta.address.label)}
              value={[
                displayValues.nameOfRecipient,
                displayValues.addressLine1,
                displayValues.addressLine2,
                displayValues.city,
                displayValues.country,
              ]}
              visibility={fieldVisibility(formMeta.address)}
            />
          </Grid>
        </>
      )}
    </ReviewSection>
  );
};

export default ReviewOfflineResponses;
