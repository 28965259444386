import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { isEmpty } from "lodash";
import { BootstrapTooltip } from "../../../../components/molecules/tooltip";

const IconizedText = ({
  icon,
  text = "",
  tooltip = "",
  containerProps = {},
  iconProps = {},
  labelProps = {},
  visibility = true,
}) => {
  if (!visibility) {
    return false;
  }

  return (
    <Grid
      container
      direction="row"
      className={containerProps.className}
      style={containerProps.style}
      wrap="nowrap"
    >
      {!isEmpty(text) && (
        <>
          <BootstrapTooltip title={tooltip}>
            <img src={icon} className={iconProps.className} alt="icon" />
          </BootstrapTooltip>

          <Typography className={labelProps.className} style={labelProps.style}>
            {text}
          </Typography>
        </>
      )}
    </Grid>
  );
};

export default IconizedText;
