import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { BootstrapTooltip } from "../../../../components/molecules/tooltip";
import topAdBadge from "../../../../../images/topad-badge.png";
import moment from "moment";
import { useTranslation } from "react-i18next";

const CardFooter = ({ advertisment, isFeatured, position }) => {
  const { t } = useTranslation();
  return (
    <Grid container direction="row" justifyContent="space-between">
      <Grid container direction="row" xs alignItems="center">
        {isFeatured && (
          <img
            src={topAdBadge}
            alt="top-ad-badge"
            className="top-ad-badge"
            title="Top Ad"
          />
        )}
        <BootstrapTooltip title={t("common.publishedDate")}>
          <Typography className="published-time-txt">
            {`Published : 
        ${moment.utc(advertisment.publishedAt).local().fromNow()}`}
          </Typography>
        </BootstrapTooltip>
      </Grid>
      <Typography
        className="see-more-txt"
        id={position === 1 ? "more-one" : null}
      >{`${t("common.moreDetails")} >`}</Typography>
    </Grid>
  );
};
export default CardFooter;
