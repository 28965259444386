import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import DataColumn from "../../../../../../components/molecules/data-column";
import ReviewSection from "../../../../../../components/molecules/review-section";
import ReviewImageSection from "../review-image-section";
import AppContext from "../../../../../../context";
import {
  DataDisplaySection,
  generateDisplayValues,
} from "../../../../../../features/createflow";
import { PrivateInformationDisplayModel } from "../../../../../../models/profile";
import {
  Chip,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { getAuthTempProfileImage } from "../../../../../../features/application";
import FormImageSelector from "../../../../../../components/molecules/form-image-selector";
import { fieldVisibility } from "../../../../../../utils";
import privateFormMeta from "../../../../private-create-account/schema/meta.json";
import reviewAndPayMeta from "../../../../review-and-pay/schema/formMeta.json";

const formMeta = { ...privateFormMeta, ...reviewAndPayMeta };

const ReviewPrivateInfo = () => {
  const { t, i18n } = useTranslation();
  const { appLanguage } = useContext(AppContext);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { authTempProfile } = useSelector((state) => state.application);
  const { initialized } = useSelector((state) => state.genericData);
  const [displayValues, setDisplayValues] = useState(
    PrivateInformationDisplayModel
  );
  const [horoscopeImages, setHoroscopeImages] = useState([]);

  const downloadHoroscopeImages = useCallback(async () => {
    if (!isEmpty(authTempProfile)) {
      const images = authTempProfile.postData?.horoscopeImages ?? [];
      const temp = [];
      for (const file of images) {
        try {
          const image = await getAuthTempProfileImage({ id: file });
          temp.push(image);
        } catch (e) {
          console.error("Error! download horoscope image ", e);
        }
      }
      setHoroscopeImages(temp);
    }
  }, [authTempProfile]);

  useEffect(() => {
    if (initialized && !isEmpty(authTempProfile)) {
      downloadHoroscopeImages();
      generateDisplayValues({
        section: DataDisplaySection.privateInformation,
        lang: appLanguage,
      }).then((values) => {
        setDisplayValues(values);
      });
    }
  }, [appLanguage, initialized, authTempProfile, downloadHoroscopeImages]);

  return (
    <ReviewSection
      heading={t("common.privateInfo")}
      navigate={"complete-ad-private"}
    >
      <Grid container className="review-info-wrapper margin-top-8">
        <ReviewImageSection
          heading={t("common.pictures")}
          isPrivate={true}
          pictures={displayValues.images}
        />
      </Grid>

      <Grid
        container
        direction="row"
        className="margin-top-24 column-gap-8"
        alignItems="center"
      >
        <Typography
          className={`review-section-title typo-h4-${i18n.language} capitalized-text`}
        >
          {t("post.horoscopeInfo")}
        </Typography>
        <Chip
          size="small"
          label="Private"
          color="primary"
          className="data-section-chip"
        />
      </Grid>

      <Grid
        container
        direction="row"
        className="data-column-container margin-top-8"
        alignItems="flex-start"
      >
        <DataColumn
          label={t(formMeta.matching.label)}
          value={displayValues.horoscopeMatching}
          visibility={fieldVisibility(formMeta.matching)}
        />
        <DataColumn
          label={t(formMeta.horoscopeCompatibility.label)}
          value={displayValues.horoscopeMatching}
          visibility={fieldVisibility(formMeta.horoscopeCompatibility)}
        />
        <DataColumn
          label={t(formMeta.acceptOtherCaste.label)}
          value={displayValues.acceptOtherCaste}
          visibility={fieldVisibility(formMeta.acceptOtherCaste)}
        />
        <DataColumn
          label={t(formMeta.birthdate.label)}
          value={displayValues.birthdate}
          visibility={fieldVisibility(formMeta.birthdate)}
        />
        <DataColumn
          label={t(formMeta.originCountryCode.label)}
          value={displayValues.birthCountry}
          visibility={fieldVisibility(formMeta.originCountryCode)}
        />
        <DataColumn
          label={t(formMeta.birthTime.label)}
          value={displayValues.birthTime}
          visibility={fieldVisibility(formMeta.birthTime)}
        />
        <DataColumn
          label={t(formMeta.birthCity.label)}
          value={displayValues.birthCity}
          visibility={fieldVisibility(formMeta.birthCity)}
        />
        <DataColumn
          label={t(formMeta.starId.label)}
          value={displayValues.star}
          visibility={fieldVisibility(formMeta.starId)}
        />
        <DataColumn
          label={t(formMeta.starSignId.label)}
          value={displayValues.startSign}
          visibility={fieldVisibility(formMeta.starSignId)}
        />
        <DataColumn
          label={t(formMeta.haveDosh.label)}
          value={displayValues.haveDosh}
          visibility={fieldVisibility(formMeta.haveDosh)}
        />
        <DataColumn
          label={t(formMeta.horoscopeDetail.label)}
          value={displayValues.additionalInfo}
          visibility={fieldVisibility(formMeta.horoscopeDetail)}
        />
      </Grid>

      {horoscopeImages.length > 0 && (
        <Grid container direction="column" className="margin-top-8">
          <Typography className="image-empty-text">
            {t("common.horoscopeImages")}
          </Typography>
          <Grid
            xs
            container
            direction="row"
            style={{ columnGap: 8, rowGap: 8 }}
            className="review-info-wrapper margin-top-8"
          >
            {horoscopeImages.map((image, idx) => (
              <Grid
                key={`horoscope-img-${idx}`}
                style={isMobileScreen ? { width: "47%" } : { width: "20%" }}
              >
                <FormImageSelector image={image} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}

      <Grid container direction="row" className="margin-top-24 column-gap-8">
        <Typography
          className={`review-section-title typo-h4-${i18n.language} capitalized-text`}
        >
          {t("common.contactDetails")}
        </Typography>
        <Chip
          size="small"
          label="Private"
          color="primary"
          className="data-section-chip"
        />
      </Grid>

      <Grid container direction="row" className="data-column-container">
        <DataColumn
          label={t(formMeta.email.label)}
          value={displayValues.email}
          visibility={fieldVisibility(formMeta.email)}
        />
        <DataColumn
          label={t(formMeta.phoneNumber.label)}
          value={displayValues.phoneNumber ?? ""}
          visibility={fieldVisibility(formMeta.phoneNumber)}
        />
      </Grid>
    </ReviewSection>
  );
};

export default ReviewPrivateInfo;
